<template>
  <div id="e-course" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdSelect
                rules="required"
                label="School Class"
                v-model="eCourse.schoolClass.name"
              >
                <option
                  v-for="schoolClass in schoolClasses"
                  :key="schoolClass.id"
                >
                  {{ schoolClass.name }}
                </option>
              </SdSelect>

              <SdSelect
                rules="required"
                label="Subject"
                v-model="eCourse.subject.name"
              >
                <option v-for="subject in subjects" :key="subject.id">
                  {{ subject.name }}
                </option>
              </SdSelect>

              <div>
                <button
                  type="submit"
                  class="button btn-120 is-primary is-capitalized is-pulled-right"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import ASSIGNED_SCHOOL_CLASSES from '../../../graphql/school_class/AssignedSchoolClasses.gql'
import SUBJECTS from '../../../graphql/subject/Subjects.gql'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'e-course',
  data() {
    return {
      schoolId: null,
      pageTitle: 'E Course',
      skipQuery: true,
      schoolClasses: [],
      subjects: [],
      assignedClasses: [],
      eCourse: {
        id: null,
        schoolClass: {
          id: null,
          name: null,
        },
        subject: {
          id: null,
          name: null,
        },
      },
    }
  },
  apollo: {
    eCourse: {
      query: gql`
        query ECourseQuery($id: ID!) {
          eCourse(id: $id) {
            schoolClass {
              id
              name
            }
            subject {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  watch: {
    'eCourse.schoolClass.name'(value) {
      if (!value) return
      if (this.schoolClasses.length === 0) return
      this.eCourse.schoolClass.id = this.schoolClasses.filter(
        (schoolClass) => schoolClass.name === value
      )[0].id

      if (this.user.role === 'educator') {
        this.subjects = this.assignedClasses.filter(
          (assignedClass) => assignedClass.name === value
        )[0].subjects
      }
    },
    'eCourse.subject.name'(value) {
      if (!value) return
      if (this.subjects.length === 0) return
      this.eCourse.subject.id = this.subjects.filter(
        (subject) => subject.name === value
      )[0].id
    },
  },
  methods: {
    fetchSchoolClasses() {
      if (this.user.role === 'host' || this.user.role === 'admin') {
        this.$apollo.addSmartQuery('schoolClasses', {
          query: SCHOOL_CLASSES,
          variables: {
            schoolId: this.schoolId,
          },
        })
        this.$apollo.addSmartQuery('subjects', {
          query: SUBJECTS,
          variables: {
            schoolId: this.schoolId,
          },
        })
      } else if (this.user.role === 'educator') {
        this.$apollo
          .query({
            query: gql`
              query assignedSchoolClassesWithSubjectsQuery($id: ID!) {
                assignedSchoolClassesWithSubjects(id: $id) {
                  subjectsGroupedBySchoolClass
                }
              }
            `,
            variables: {
              id: this.user.id,
            },
          })
          .then((response) => {
            this.assignedClasses =
              response.data.assignedSchoolClassesWithSubjects.subjectsGroupedBySchoolClass
            this.assignedClasses.forEach((item) => {
              this.schoolClasses.push({ id: item.id, name: item.name })
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
    submit() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateECourse($schoolClassId: Int!, $subjectId: Int!) {
                createECourse(
                  input: {
                    schoolClassId: $schoolClassId
                    subjectId: $subjectId
                  }
                ) {
                  eCourse {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              schoolClassId: parseInt(this.eCourse.schoolClass.id),
              subjectId: parseInt(this.eCourse.subject.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createECourse.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/e_courses`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateECourse(
                $id: Int!
                $schoolClassId: Int!
                $subjectId: Int!
              ) {
                updateECourse(
                  input: {
                    id: $id
                    schoolClassId: $schoolClassId
                    subjectId: $subjectId
                  }
                ) {
                  eCourse {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.$route.params.id),
              schoolClassId: parseInt(this.eCourse.schoolClass.id),
              subjectId: parseInt(this.eCourse.subject.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateECourse.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/e_courses`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  components: {
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
      this.fetchSchoolClasses()
      this.$store.commit('setSubMenus', [
        {
          name: 'E Courses',
          route: `/school/${user.school_id}/e_courses`,
        },
      ])
    })
  },
}
</script>

<style lang="scss" scoped></style>
style
